import { Environment } from "./environment";
import { User } from "./user";

export interface UserEnvironmentAuthorization {
    id: string;
    userId: string;
    environmentId: string;
    mode: UserEnvironmentAuthorizationMode;
    environment: Environment;
    user: User;
}

export enum UserEnvironmentAuthorizationMode {
    READ_WRITE = 'READ_WRITE'
}