import { ComplexExpression } from "./complex-expression";
import { Metric } from "./metric";
import { PropertyDefinition } from "./property-definition";
import { ThingDefinition } from "./thing-definition";

export interface AlertDefinition {
    id: string;
    name: string;
    title: string;
    description: string;
    severity: AlertSeverity;
    category: AlertCategory;
    eventSeverity: number;
    thingDefinition: ThingDefinition;
    activeConditionCustomerPropertyDefinitionId: string;
    activeConditionLocationPropertyDefinitionId: string;
    activeConditionThingPropertyDefinition: PropertyDefinition;
    activeConditionThingDefinitionPropertyDefinition: PropertyDefinition;
    activeConditionMetric: Metric;
    activeConditionPredicate: AlertDefinitionConditionPredicate;
    activeConditionValue: string;
    activeConditionValueMetric: Metric;
    activeConditionValueCustomerPropertyDefinitionId: string;
    activeConditionValueLocationPropertyDefinitionId: string;
    activeConditionValueThingPropertyDefinition: PropertyDefinition;
    activeConditionValueThingDefinitionPropertyDefinition: PropertyDefinition;
    activeConditionSeparator: string;
    clearConditionMetric: Metric;
    clearConditionCustomerPropertyDefinitionId: string;
    clearConditionLocationPropertyDefinitionId: string;
    clearConditionThingPropertyDefinition: PropertyDefinition;
    clearConditionThingDefinitionPropertyDefinition: PropertyDefinition;
    clearConditionPredicate: string;
    clearConditionValue: string;
    clearConditionValueMetric: Metric;
    clearConditionValueCustomerPropertyDefinitionId: string;
    clearConditionValueLocationPropertyDefinitionId: string;
    clearConditionValueThingPropertyDefinition: PropertyDefinition;
    clearConditionValueThingDefinitionPropertyDefinition: PropertyDefinition;
    clearConditionSeparator: string;
    minimumActiveTime: number;
    minimumActiveTimeUnit: AlertDefinitionTimeUnit;
    alertActivatedNotificationTitle: string;
    alertActivatedNotificationLongMessage: string;
    alertActivatedNotificationShortMessage: string;
    alertActivatedInfobipFlowId: string;
    alertClearedNotificationTitle: string;
    alertClearedNotificationLongMessage: string;
    alertClearedNotificationShortMessage: string;
    alertClearedInfobipFlowId: string;
    delayedNotificationTime: number;
    delayedNotificationTimeUnit: AlertDefinitionTimeUnit;
    technicalDescription: string;
    manualActivation: boolean;
    userTypeFiltered: boolean;
    userTypeIds: string[];
    contactsNotificationsEnabled: boolean;
    activatedInfobipCustomAttributes: { [key: string]: string };
    clearedInfobipCustomAttributes: { [key: string]: string };
    validityPeriodEnabled: boolean;
    validityStartMonth: number;
    validityStartDay: number;
    validityEndMonth: number;
    validityEndDay: number;
    openTicketOnAlertActivation: boolean;
    openTicketOnAlertClearing: boolean;
    templateName: string;
    activeCondition: ComplexExpression;
    clearCondition: ComplexExpression;
    group: string;
    eventOnly: boolean;
    frequencyAlertDefinitionId: string;
    frequencyMinCount: number;
    frequencyPeriod: AlertFrequencyPeriod;
    activeConditionWearMetricId: string;
    topics: EventTopic[];
    serviceLevelIds: string[];
}

export enum AlertSeverity {
    INFORMATIONAL = 'INFORMATIONAL',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL',
    EMERGENCY = 'EMERGENCY'
}

export enum AlertCategory {
    INFORMATION = 'INFORMATION',
    ADVICE = 'ADVICE',
    ANOMALY = 'ANOMALY',
    FAILURE = 'FAILURE',
    SAFETY = 'SAFETY'
}

export enum AlertDefinitionConditionPredicate {
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    LESS = 'LESS',
    GREATER = 'GREATER',
    LESS_EQUAL = 'LESS_EQUAL',
    GREATER_EQUAL = 'GREATER_EQUAL',
    EXPIRED_BY_N_DAYS = 'EXPIRED_BY_N_DAYS',
    CONTAINS = 'CONTAINS',
    EXPIRED_BY_N_HOURS = 'EXPIRED_BY_N_HOURS'
}

export enum AlertDefinitionTimeUnit {
    SECONDS = 'SECONDS',
    MINUTES = 'MINUTES'
}

export enum AlertCategoryGroup {
    FAILURE = 'FAILURE',
    ANOMALY = 'ANOMALY',
    OPERATION = 'OPERATION'
}

export const ALERT_FIELDS: string[] = [
    'alertActivatedNotificationTitle',
    'alertActivatedNotificationLongMessage',
    'alertActivatedNotificationShortMessage',
    'alertActivatedInfobipFlowId',
    'alertClearedNotificationTitle',
    'alertClearedNotificationLongMessage',
    'alertClearedNotificationShortMessage',
    'alertClearedInfobipFlowId',
    'delayedNotificationTime',
    'delayedNotificationTimeUnit',
    'userTypeFiltered',
    'userTypeIds',
    'contactsNotificationsEnabled',
    'activatedInfobipCustomAttributes',
    'clearedInfobipCustomAttributes',
    'eventOnly'
]

export enum AlertFrequencyPeriod {
    HOURS_1 = 'HOURS_1',
    DAYS_1 = 'DAYS_1',
    DAYS_7 = 'DAYS_7',
    DAYS_30 = 'DAYS_30'
}

export enum EventTopic {
    CONNECTION = 'CONNECTION',
    UPTIME = 'UPTIME',
    DOWNTIME = 'DOWNTIME',
    HEALTH = 'HEALTH',
    PRODUCTIVITY = 'PRODUCTIVITY',
    QUALITY = 'QUALITY',
    CONSUMPTION = 'CONSUMPTION',
    MAINTENANCE = 'MAINTENANCE'
}