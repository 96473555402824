import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HomeAssistantConfig, HomeAssistantType, Plugin, PluginCategoryConfiguration, PluginConfig } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class PluginService extends ResourceService<Plugin> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'plugins';
    }

    getByName(name: string): Promise<Plugin> {
        let params = this.getContextParams();
        params = params.set('name', name)
        return firstValueFrom(this.httpClient.get<Plugin>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    test(body: any, id: string, extraParams?: HttpParams): Promise<string> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.post(AppConstants.API_BASE_URL + this.getEndpointById(id) + '/test', body, { params: params, responseType: 'text' }));
    }

    downloadFile(id: string, propName: string): void {
        let params = this.getContextParams();
        firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getFileEnpoint(id, propName), { observe: "response", responseType: "blob", params: params }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, Utilities.getFileNameFromResponse(response.headers, 'file')) });
    }

    deleteFile(id: string, propName: string): Promise<void> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getFileEnpoint(id, propName), { params: params }));
    }

    saveFile(id: string, propName: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getFileEnpoint(id, propName), formData, { params: params }));
    }

    private getFileEnpoint(id: string, propName: string): string {
        return `${this.getEndpoint()}/${id}/files/${propName}`;
    }

    getPluginConfigurations(): Promise<{ categories: PluginCategoryConfiguration[], plugins: PluginConfig[] }> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<{ categories: PluginCategoryConfiguration[], plugins: PluginConfig[] }>(this.getPluginConfigEndpoint(), { params: params }));
    }

    getPluginConfigurationByName(name: string): Promise<PluginConfig> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<PluginConfig>(this.getPluginConfigEndpoint() + '/' + name, { params: params }));
    }

    getHomeAssistantPluginConfiguration(type: HomeAssistantType): Promise<HomeAssistantConfig> {
        let params = new HttpParams().set('type', type);
        return firstValueFrom(this.httpClient.get<HomeAssistantConfig>(this.getPluginConfigEndpoint() + '/homeAssistant', { params: params }));
    }

    private getPluginConfigEndpoint(): string {
        return AppConstants.API_BASE_URL + 'pluginConfigurations';
    }
}