import { AlertCategory, AlertDefinitionConditionPredicate, AlertDefinitionTimeUnit, AlertSeverity, EventTopic } from "./alert-definition";

export interface LocationAlertDefinition {
    id: string;
    name: string;
    title: string;
    description: string;
    severity: AlertSeverity;
    category: AlertCategory;
    eventSeverity: number;
    activeConditionMetricId: string;
    activeConditionCustomerPropertyDefinitionId: string;
    activeConditionLocationPropertyDefinitionId: string;
    activeConditionPredicate: AlertDefinitionConditionPredicate;
    activeConditionValue: string;
    activeConditionValueMetricId: string;
    activeConditionValueCustomerPropertyDefinitionId: string;
    activeConditionValueLocationPropertyDefinitionId: string;
    activeConditionSeparator: string;
    clearConditionMetricId: string;
    clearConditionCustomerPropertyDefinitionId: string;
    clearConditionLocationPropertyDefinitionId: string;
    clearConditionPredicate: AlertDefinitionConditionPredicate;
    clearConditionValue: string;
    clearConditionValueMetricId: string;
    clearConditionValueCustomerPropertyDefinitionId: string;
    clearConditionValueLocationPropertyDefinitionId: string;
    clearConditionSeparator: string;
    minimumActiveTime: number;
    minimumActiveTimeUnit: AlertDefinitionTimeUnit;
    alertActivatedNotificationTitle: string;
    alertActivatedNotificationLongMessage: string;
    alertActivatedNotificationShortMessage: string;
    alertActivatedInfobipFlowId: string;
    alertClearedNotificationTitle: string;
    alertClearedNotificationLongMessage: string;
    alertClearedNotificationShortMessage: string;
    alertClearedInfobipFlowId: string;
    delayedNotificationTime: number;
    delayedNotificationTimeUnit: AlertDefinitionTimeUnit;
    technicalDescription: string;
    manualActivation: boolean;
    userTypeFiltered: boolean;
    userTypeIds: string[];
    contactsNotificationsEnabled: boolean;
    activatedInfobipCustomAttributes: { [key: string]: string };
    clearedInfobipCustomAttributes: { [key: string]: string };
    validityPeriodEnabled: boolean;
    validityStartMonth: number;
    validityStartDay: number;
    validityEndMonth: number;
    validityEndDay: number;
    group: string;
    eventOnly: boolean;
    topics: EventTopic[];
}

export const LOCATION_ALERT_FIELDS: string[] = [
    'alertActivatedNotificationTitle',
    'alertActivatedNotificationLongMessage',
    'alertActivatedNotificationShortMessage',
    'alertActivatedInfobipFlowId',
    'alertClearedNotificationTitle',
    'alertClearedNotificationLongMessage',
    'alertClearedNotificationShortMessage',
    'alertClearedInfobipFlowId',
    'delayedNotificationTime',
    'delayedNotificationTimeUnit',
    'userTypeFiltered',
    'userTypeIds',
    'contactsNotificationsEnabled',
    'activatedInfobipCustomAttributes',
    'clearedInfobipCustomAttributes',
    'eventOnly'
]