import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Partner } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class PartnerService extends ResourceService<Partner> {


    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'partners';
    }

    patchDomain(id: any, body: { domainId: string; }): Promise<void> {
        return firstValueFrom(this.httpClient.patch<void>(AppConstants.API_BASE_URL + this.getEndpointById(id) + '/domain', body, { params: this.getContextParams() }));
    }

}