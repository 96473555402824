import { Component, Inject, inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { MaterialModule } from "../../../material.module";

@Component({
    standalone: true,
    selector: 'notification-snackbar',
    imports: [MaterialModule],
    templateUrl: 'notification-snackbar.component.html'
})
export class NotificationSnackbarComponent {
    snackBarRef = inject(MatSnackBarRef);
    message: string;
    action: NotificationSnackbarAction

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
        this.message = data.message;
        this.action = data.action;
    }

    performAction(): void {
        this.snackBarRef.dismissWithAction();
        this.action.callback();
    }
}

export class NotificationSnackbarAction {
    label: string;
    callback: () => void;
}