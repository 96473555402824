export class ConnectionMapping {
	type: ConnectionMappingType;
	baseRestUrl: string;
	mqttBrokerUrl: string;
	username: string;
	password: string;
	payloadFormat: PayloadFormat;
	dataRetentionTtl: number;
	accountName: string;
	accountKey: string;
	namespace: string;
	thingProvisioningPolicy: ThingProvisioningPolicy;
	registryMetricPath: string;
	registryMetricName: string;
	maxParameterCountPerMessage: number;
	assetIds: string | string[];
	brokerHost: string;
	brokerVhost: string;
	brokerPort: string;
	brokerUsername: string;
	brokerPassword: string;
	backendUsername: string;
	backendPassword: string;
	truststoreCertificatePresent: boolean;
	truststorePassword: string;
	keystoreCertificatePresent: boolean;
	keystorePassword: string;
	queueName: string;
	status: { severity: string, details: string };
}

export enum ConnectionMappingType {
	ALLEANTIA = 'ALLEANTIA',
	AWS_IOT_CORE = 'AWS_IOT_CORE',
	AZURE_SERVICE_BUS = 'AZURE_SERVICE_BUS',
	EDC = 'EDC',
	HTTP_CONNECTOR = 'HTTP_CONNECTOR',
	JMF = 'JMF',
	LEMONBEAT = 'LEMONBEAT',
	SEMIOTY_THING_CONNECTOR = 'SEMIOTY_THING_CONNECTOR'
}

export enum PayloadFormat {
	JSON = 'JSON',
	UNIQLOUD = 'UNIQLOUD'
}

export enum ThingProvisioningPolicy {
	PLUG_AND_PLAY = 'PLUG_AND_PLAY'
}

