import { Injectable } from "@angular/core";
import { TokenStorageService } from "./token-storage.service";

declare var SockJS: any;
declare var Stomp: any;

@Injectable({ providedIn: 'root' })
export class SocketService {

	private socket: any;
	private client: any;

	constructor(
		private tokenService: TokenStorageService
	) { }

	connect(): void {
		this.socket = new SockJS('/ws');
		this.client = Stomp.over(this.socket);
		this.client.debug = null;
		let headers = {};
		const token = this.tokenService.getToken();
		if (token) {
			headers = { 'Authorization': 'Bearer ' + token };
		}
		this.client.connect(headers,
			() => { console.log('STOMP: Connected') },
			(err: any) => { console.log(err) }
		)
	}

	disconnect(): void {
		if (this.client?.connected) {
			this.client.disconnect();
			this.client = null;
			this.socket = null;
		}
	}

	subscribe(topic: string, callback: Function): any {
		return this.client?.subscribe(topic, callback);
	}

	unsubscribe(subscription: any): void {
		subscription.unsubscribe();
	}
}