export interface ComplexExpression {
    variables: ComplexExpressionVariable[];
    expressions: ConditionalExpression[];
    defaultExpression: string;
}

export interface ComplexExpressionVariable {
    name: string;
    refId: string;
    type: ComplexExpressionVariableType;
}

export interface ConditionalExpression {
    condition: string;
    expression: string;
}

export enum ComplexExpressionVariableType {
    METRIC = 'METRIC',
    METRIC_RECORD = 'METRIC_RECORD',
    THING_PROPERTY = 'THING_PROPERTY',
    THING_FIELD = 'THING_FIELD',
    THING_DEFINITION_PROPERTY = 'THING_DEFINITION_PROPERTY',
    CUSTOMER_PROPERTY = 'CUSTOMER_PROPERTY',
    LOCATION_PROPERTY = 'LOCATION_PROPERTY',
    PREVIOUS_VALUE = 'PREVIOUS_VALUE'
}