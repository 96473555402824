import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { UserNotification } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class UserNotificationService extends ResourceService<UserNotification> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'notifications';
    }

    override getRecursivelyAll(extraParams?: HttpParams): Promise<UserNotification[]> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.get<UserNotification[]>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

}