import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { EnvironmentVersion } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class EnvironmentVersionService extends ResourceService<EnvironmentVersion> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'environmentVersions';
    }

    export(id: string): Promise<void> {
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getEndpointById(id) + '/export', {}));
    }

    getExportUrl(id: string): Promise<{ url: string }> {
        return firstValueFrom(this.httpClient.get<{ url: string }>(AppConstants.API_BASE_URL + this.getEndpointById(id) + '/exportUrl'));
    }
}