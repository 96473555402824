import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ThingBillingData } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ThingBillingDataService extends ResourceService<ThingBillingData> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'thingBillingData';
    }

    getPeriods(): Promise<string[]> {
        return firstValueFrom(this.httpClient.get<string[]>(AppConstants.API_BASE_URL + this.getEndpoint() + '/periods', { params: this.getContextParams() }));
    }

    export(year: string, month: string, monthly: boolean, searchText: string): Promise<void> {
        let params = new HttpParams().set('year', year).set('month', month).set('monthly', monthly);
        if (searchText) {
            params = params.set('searchText', '*' + searchText + '*');
        }
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getEndpoint() + '/export', { observe: "response", responseType: "blob", params: params }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, 'billing_' + year + month + '.csv') });
    }

}