<div class="notification-content d-flex align-items-center">
  <div matSnackBarLabel>
    <div class="sy-subtitle-2">{{message}}</div>
  </div>
  <div matSnackBarActions>
    @if (action) {
      <button mat-stroked-button class="action-button"
      (click)="performAction()">{{action.label}}</button>
    }
    <button mat-icon-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>