import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { UiProfile, UiProfilePage, UiProfilePageConfig } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class UiProfilePageService extends ResourceService<UiProfilePage> {

    private detailsPagesIds = ['customerDetails', 'locationDetails', 'partnerDetails'];
    private alertDetailsPagesIds = ['eventDetails'];

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'uiProfilePages';
    }

    getMenuIcon(pageId: string): Promise<Blob> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<Blob>(AppConstants.API_BASE_URL + this.getMenuIconEndpoint(pageId), { observe: "response", responseType: 'blob' as 'json', params: params }))
            .then(response => response.body).catch(() => null);
    }

    saveMenuIcon(pageId: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getMenuIconEndpoint(pageId), formData, { params: params }));
    }

    private getMenuIconEndpoint(pageId: string): string {
        return this.getEndpoint() + '/' + pageId + '/menuIcon';
    }

    isDetailsPage(id: string): boolean {
        return this.detailsPagesIds.includes(id) || this.isAlertDetailsPage(id);
    }

    isAlertDetailsPage(id: string): boolean {
        return this.alertDetailsPagesIds.includes(id);
    }

    getDetailsPageById(id: string, uiProfile: UiProfile): UiProfilePage {
        return this.getDetailsPages(uiProfile).find(p => p.id == id);
    }

    getDetailsPages(uiProfile: UiProfile): UiProfilePage[] {
        return [
            {
                id: 'customerDetails',
                name: 'Customer details',
                uiProfileId: uiProfile.id,
                urlPath: 'customer_details/<customer-id>',
                informationalBadge: null,
                userTypeIds: null,
                tabs: uiProfile.customerDetailsTabs,
                navigation: null,
                templateName: null,
                visibilityCondition: null,
                external: false,
                externalUrl: null,
                type: null,
                showStoreCatalog: false,
                storeCatalogLayoutMode: null,
                controlBarTemplateName: null
            },
            {
                id: 'locationDetails',
                name: 'Location details',
                uiProfileId: uiProfile.id,
                urlPath: 'location_details/<location-id>',
                informationalBadge: null,
                userTypeIds: null,
                tabs: uiProfile.locationDetailsTabs,
                navigation: null,
                templateName: null,
                visibilityCondition: null,
                external: false,
                externalUrl: null,
                type: null,
                showStoreCatalog: false,
                storeCatalogLayoutMode: null,
                controlBarTemplateName: null
            },
            {
                id: 'partnerDetails',
                name: 'Partner details',
                uiProfileId: uiProfile.id,
                urlPath: 'partner_details/<partner-id>',
                informationalBadge: null,
                userTypeIds: null,
                tabs: uiProfile.partnerDetailsTabs,
                navigation: null,
                templateName: null,
                visibilityCondition: null,
                external: false,
                externalUrl: null,
                type: null,
                showStoreCatalog: false,
                storeCatalogLayoutMode: null,
                controlBarTemplateName: null
            },
            {
                id: 'eventDetails',
                name: 'Event details',
                uiProfileId: uiProfile.id,
                urlPath: 'event_details/<alert-id>',
                informationalBadge: null,
                userTypeIds: null,
                tabs: null,
                navigation: null,
                templateName: uiProfile.activeAlertDetailsTemplate,
                visibilityCondition: null,
                external: false,
                externalUrl: null,
                type: null,
                showStoreCatalog: false,
                storeCatalogLayoutMode: null,
                controlBarTemplateName: null
            }
        ]
    }

    getUiProfilePageConfigurations(): Promise<{ uiProfilePages: UiProfilePageConfig[] }> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<UiProfilePageConfig[]>(this.getUiProfilePageConfigEndpoint(), { params: params })).then(configurations => {
            return { uiProfilePages: configurations };
        });
    }

    private getUiProfilePageConfigEndpoint(): string {
        return AppConstants.API_BASE_URL + 'uiProfilePageConfigurations';
    }

}
