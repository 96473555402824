import { AlgorithmsRuntime, CountableType, DictionaryItem, MetricAlgorithmBinding, MetricValueType, ValueRange } from "./metric";

export interface LocationMetric {
    id: string;
    name: string;
    label: string;
    unit: string;
    type: NetworkMetricType;
    valueType: MetricValueType;
    computationPeriod: NetworkMetricComputationPeriod;
    inputMetrics: InputMetric[];
    datasetRange: NetworkMetricDatasetRange;
    min: number;
    max: number;
    ranges: ValueRange[];
    inputLocationMetrics: InputLocationMetric[];
    manualResetEnabled: boolean;
    resetValue: string;
    dataSource: NetworkMetricDataSourceType;
    measurement: string;
    editableResetValue: boolean;
    countableType: CountableType;
    dictionary: DictionaryItem[];
    algorithmsRuntime: AlgorithmsRuntime;
    algorithmBinding: MetricAlgorithmBinding;
}

export enum NetworkMetricType {
    ALGORITHM = 'ALGORITHM',
    SIMPLE = 'SIMPLE'
}

export enum NetworkMetricComputationPeriod {
    DAYS_1 = 'DAYS_1',
    WEEKS_1 = 'WEEKS_1',
    MONTHS_1 = 'MONTHS_1',
    YEARS_1 = 'YEARS_1'
}

export class InputMetric {
    thingDefinitionId: string;
    metricId: string;
    variable: string
}

export enum NetworkMetricDatasetRange {
    LAST_VALUE = 'LAST_VALUE',
    LAST_24_HOURS = 'LAST_24_HOURS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    THIS_WEEK = 'THIS_WEEK',
    THIS_MONTH = 'THIS_MONTH',
    LAST_100_VALUES = 'LAST_100_VALUES',
    LAST_500_VALUES = 'LAST_500_VALUES',
    LAST_1000_VALUES = 'LAST_1000_VALUES'
}

export class InputLocationMetric {
    locationMetricId: string;
    variable: string;
}

export enum NetworkMetricDataSourceType {
    OPEN_WEATHER_MAP = 'OPEN_WEATHER_MAP'
}
