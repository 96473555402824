import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SparePartDefinition } from "../models/spare-part-definition";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class SparePartDefinitionService extends ResourceService<SparePartDefinition> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'sparePartDefinitions';
    }

    import(file: File): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        const params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<any>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/import`, formData, { params: params }));
    }

    export(): Promise<void> {
        const params = this.getContextParams();
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + `${this.getEndpoint()}/export`, { params: params, observe: "response", responseType: "blob" }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, 'spare_parts_export.csv') });
    }

}