import { Condition } from "./condition";
import { Metric, MetricByteOrder, MetricValueEncoding } from "./metric";
import { ThingDefinition } from "./thing-definition";
import { Transformer } from "./transformer";

export interface ConfigurationParameter {
    id: string;
    name: string;
    label: string;
    type: ConfigurationParameterType;
    thingDefinition: ThingDefinition;
    values: ConfigurationParameterDictionaryValue[];
    minValue: string;
    maxValue: string;
    stepValue: string;
    mandatory: boolean;
    metric: Metric;
    mapping: ConfigurationParameterMapping;
    enabledCondition: Condition;
    selectionMode: ConfigurationParameterSelectionMode;
    multiple: boolean;
    resultBlob: ResultBlobType;
    useCustomEditor: boolean;
    editorConfigurationId: string;
    thingUpdateFieldType: ThingUpdateFieldType;
    thingPropertyDefinitionId: string;
    bulkExecution: boolean;
    deferredBulkExecution: boolean;
    retained: boolean;
    group: string;
    description: string;
    retryCount: number;
    retryIntervalSeconds: number;
    retryAlertDefinitionId: string;
}

export enum ConfigurationParameterType {
    BOOLEAN = 'BOOLEAN',
    DOUBLE = 'DOUBLE',
    FLOAT = 'FLOAT',
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    STRING = 'STRING',
    BASE64 = 'BASE64',
    BLOB = 'BLOB',
    DATE = 'DATE'
}

export interface ConfigurationParameterDictionaryValue {
    value: string;
    label: string;
}

export interface ConfigurationParameterMapping {
    path: string;
    name: string;
    valueTransformer: Transformer;
    valueBuiltInTransformer: BuiltInTransformerType;
    dataTransformer: Transformer;
    valueEncoding: MetricValueEncoding;
    binaryPosition: number;
    binaryLength: number;
    binaryByteOrder: MetricByteOrder;
    rawBinaryMetric: Metric;
}

export enum BuiltInTransformerType {
    DIVIDED_BY_10 = "DIVIDED_BY_10",
    DIVIDED_BY_100 = "DIVIDED_BY_100",
    TWO_COMPLEMENT = "TWO_COMPLEMENT",
    TWO_COMPLEMENT_DIVIDED_BY_10 = "TWO_COMPLEMENT_DIVIDED_BY_10",
    TWO_COMPLEMENT_DIVIDED_BY_100 = "TWO_COMPLEMENT_DIVIDED_BY_100",
    BOOLEAN_TO_INTEGER = "BOOLEAN_TO_INTEGER",
}

export enum ConfigurationParameterSelectionMode {
    RADIO_BUTTON = 'RADIO_BUTTON',
    SELECTION = 'SELECTION',
    SWITCH = 'SWITCH'
}

export enum ResultBlobType {
    ZIP = 'ZIP',
    CONCAT = 'CONCAT'
}

export enum ThingUpdateFieldType {
    NAME = 'NAME',
    SERIAL_NUMBER = 'SERIAL_NUMBER',
    GPS_POSITION = 'GPS_POSITION',
    TAGS = 'TAGS',
    PARENT_THING = 'PARENT_THING',
    PROPERTY = 'PROPERTY'
}