import { Component, HostListener, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MaterialModule } from "../../../material.module";

@Component({
    standalone: true,
    selector: 'confirm-dialog',
    imports: [MaterialModule],
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialog {

    title: string;
    message: string;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.title = data.title;
        this.message = data.message;
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.dialogRef.close(false);
        } else if (event.key === 'Enter') {
            this.dialogRef.close(true);
        }
    }

}