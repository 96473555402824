import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationSnackbarAction, NotificationSnackbarComponent } from "../shared/components/notification-snackbar/notification-snackbar.component";
import { Utilities } from "../shared/utilities/utilities";

@Injectable({ providedIn: 'root' })
export class NotificationService {

    constructor(private snackBar: MatSnackBar) { }

    showErrorMessage(err: any): void {
        let message = Utilities.extractMessageFromError(err);
        if (!message) {
            message = 'Error';
        }
        this.snackBar.openFromComponent(NotificationSnackbarComponent, {
            duration: 10000,
            panelClass: 'notification-error',
            data: { message: message }
        });
    }

    showInfoMessage(message: string, action?: NotificationSnackbarAction): void {
        this.snackBar.openFromComponent(NotificationSnackbarComponent, {
            duration: action ? 10000 : 5000,
            panelClass: 'notification-info',
            data: { message: message, action: action }
        });
    }

    showWarningMessage(message: string, action?: NotificationSnackbarAction): void {
        this.snackBar.openFromComponent(NotificationSnackbarComponent, {
            duration: action ? 10000 : 5000,
            panelClass: 'notification-warning',
            data: { message: message, action: action }
        });
    }
}