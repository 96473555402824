import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class TextService extends ResourceService<string> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'texts';
    }

    getByKey(key: string): Promise<string> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getEndpoint() + '/' + key, { params: params, responseType: 'text' }));
    }

}