import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SparePartDefinitionCategory } from "../models/spare-part-definition-category";
import { ActionMenuButtons } from "../shared/components/custom-table/custom-table.component";
import { TreeNode } from "../shared/components/tree-list/tree-list.component";
import { ContextService } from "./context.service";
import { FlatTreeNode, FlatTreeService } from "./flat-tree.service";
import { ResourceService } from "./resource.service";
import { TreeService } from "./tree.service";

@Injectable({ providedIn: 'root' })
export class SparePartDefinitionCategoryService extends ResourceService<SparePartDefinitionCategory> {

    private treeNodeIcon = "communities";

    constructor(
        private flatTreeService: FlatTreeService,
        private treeService: TreeService,
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'sparePartDefinitionCategories';
    }

    findAllDescendants(categoryId: string, sparePartDefinitionCategories: SparePartDefinitionCategory[]): string[] {
        return this.treeService.findAllDescendants(categoryId, sparePartDefinitionCategories, "parentId");
    }

    private getActionMenuElements(onlyOneRoot?: boolean): ActionMenuButtons[] {
        let actions = [
            { label: 'Edit', name: 'click_spare_part_category', icon: 'edit' },
            { label: 'Move', name: 'move_spare_part_category', icon: 'swap_horiz' },
            { label: 'Add Category', name: 'add_category', icon: 'add' },
            { label: 'Add Spare Part', name: 'add_spare_part', icon: 'add' },
            { label: 'Delete', name: 'delete_spare_part_category', icon: 'delete', buttonClass: 'warn' }
        ];
        if (onlyOneRoot) {
            actions.splice(1, 1);
        }
        return actions;
    }

    fillTreeNodes(sparePartDefinitionCategories: SparePartDefinitionCategory[]): TreeNode[] {
        this.treeService.enrichElementsWithActions(sparePartDefinitionCategories, this.getActionMenuElements());
        let treeNodes: TreeNode[] = this.treeService.fillTreeNodes(sparePartDefinitionCategories, "parentId", this.treeNodeIcon, "fill");
        if(treeNodes.length == 1){  // removes move action if only one root
            treeNodes[0].element['actionMenuButtons'] = this.getActionMenuElements(true);
        }
        return treeNodes;
    }

    getFlatTree(sparePartDefinitionCategories: SparePartDefinitionCategory[]): FlatTreeNode[] {
        const tree = this.fillTreeNodes(sparePartDefinitionCategories);
        return this.flatTreeService.getFlatTree(tree);
    }

}