<div mat-dialog-title class="d-flex align-items-center">
	<div class="d-flex flex-column">
		<h5 class="sy-overline">Warning</h5>
		<h2 class="sy-headline-6">Conflict</h2>
	</div>
	<button mat-icon-button [mat-dialog-close]="false" class="ms-auto">
		<mat-icon>close</mat-icon>
	</button>
</div>
<mat-dialog-content class="d-flex flex-column align-items-center">
	<mat-icon class="material-symbols-outlined fw-500" color="warn">sync_problem</mat-icon>
	<span class="sy-body-1 text-center">
		Another user just edited this content. <br />
		Unfortunately you can't proceed with saving unless you refresh the page <br /><br />
		In order not to lose yout work you can download it.
	</span>
	<button mat-button (click)="download()" color="primary">
		<mat-icon class="material-symbols-outlined fw-500">download</mat-icon>
		Download your version
	</button>
</mat-dialog-content>
@if (downloaded) {
	<mat-dialog-actions>
		<button mat-flat-button class="flex-fill" color="warn" [mat-dialog-close]="true">Close and refresh page</button>
	</mat-dialog-actions>
}
