import { UiTab } from "./ui-tab";
import { UserTypeGroup } from "./user-type";

export interface UiProfile {
    id: string;
    name: string;
    userTypeIds: string[];
    sidebarPageIds: string[];
    customerDetailsTabs: UiTab[];
    locationDetailsTabs: UiTab[];
    partnerDetailsTabs: UiTab[];
    mainMenuStyle: UiProfileMainMenuStyle;
    mainMenuCollapsable: boolean;
    activeAlertDetailsTemplate: string;
    userMenuStyle: UiProfileUserMenuStyle;
    group: UserTypeGroup;
    controlBarTemplateName: string;
}

export enum UiProfileMainMenuStyle {
    ICON_AND_TEXT = 'ICON_AND_TEXT',
    ICON = 'ICON'
}

export enum UiProfileUserMenuStyle {
    NAVIGATION_BAR_DROPDOWN = 'NAVIGATION_BAR_DROPDOWN',
    SIDEBAR_ITEMS = 'SIDEBAR_ITEMS'
}