import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { GroupedSelectOption, PermissionCategoryConfiguration, UserType, UserTypeGroup } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class UserTypeService extends ResourceService<UserType> {

    static ALL_USER_TYPES = 'All User Types';

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'userTypes';
    }

    groupUserTypes(userTypes: UserType[]): GroupedSelectOption[] {
        let groupedUserTypes: GroupedSelectOption[] = [];
        userTypes.forEach(ut => {
            let opt = groupedUserTypes.find(opt => opt.name == ut.category);
            if (!opt) {
                opt = { name: ut.category, options: [] };
                groupedUserTypes.push(opt);
            }
            opt.options.push({ label: ut.name, value: ut.id });
        });
        return groupedUserTypes;
    }

    getPermissionConfigurations(): Promise<{ categories: PermissionCategoryConfiguration[] }> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<{ categories: PermissionCategoryConfiguration[] }>(AppConstants.API_BASE_URL + 'permissionConfigurations', { params: params }));
    }

    static getGroupIcon(group: UserTypeGroup): string {
        switch (group) {
            case UserTypeGroup.BACK_OFFICE: return 'dvr';
            case UserTypeGroup.SERVICE: return 'construction';
            case UserTypeGroup.CUSTOMER: return 'guardian';
            default: return '';
        }
    }

}