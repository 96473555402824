import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Feature } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class FeatureService extends ResourceService<Feature> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'features';
    }

    getByEnvironmentId(envId: string): Promise<Feature[]> {
        let params = this.getContextParams().set('environmentId', envId);
        return firstValueFrom(this.httpClient.get<Feature[]>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }
}