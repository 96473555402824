import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { User } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";
import { Utilities } from "../shared/utilities/utilities";

@Injectable({ providedIn: 'root' })
export class UserService extends ResourceService<User> {

	constructor(
		httpClient: HttpClient,
		contextService: ContextService
	) { super(httpClient, contextService); }

	protected getEndpoint(): string {
		return 'users';
	}

	override save(body: any, id?: string, extraParams?: HttpParams): Promise<User> {
		let params = this.getContextParams(extraParams);
		if (id) {
			return firstValueFrom(this.httpClient.patch<void>(AppConstants.API_BASE_URL + this.getEndpointById(id), body, { params: params }))
				.then(() => this.getById(id, extraParams));
		} else {
			return firstValueFrom(this.httpClient.post<User>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: params }));
		}
	}

	passwordReset(): Promise<void> {
		return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/me/passwordReset`, null))
	}

	sendActivationEmail(id: string): Promise<void> {
		return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + `${this.getEndpointById(id)}/sendActivationEmail`, null));
	}

	exportAll(): Promise<void> {
		return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + `${this.getEndpoint()}/exportAll`, { observe: "response", responseType: "blob" }))
			.then(response => { Utilities.wrapFileAndDownload(response.body, 'users_export.csv') });
	}
}
