<div mat-dialog-title class="d-flex align-items-center">
  <div class="d-flex flex-column">
    <h5 class="sy-overline">{{config.subtitle}}</h5>
    <h2 class="sy-headline-6">{{config.title}}</h2>
  </div>
  <button mat-icon-button [mat-dialog-close]="false" class="ms-auto">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="d-flex flex-column align-items-center">
  <mat-icon class="material-symbols-outlined fw-500">{{config.icon}}</mat-icon>
  @if (config.message) {
    <span class="sy-body-1 text-center">{{config.message}}</span>
  }
  @if (config.html) {
    <span class="sy-body-1" [innerHTML]="config.html | noSanitize"></span>
  }
</mat-dialog-content>
<mat-dialog-actions>
  @if (config.closeButton) {
    <button mat-stroked-button class="flex-fill" color="primary"
    [mat-dialog-close]="false">{{config.closeButton}}</button>
  }
  @if (config.confirmButton) {
    <button mat-flat-button class="flex-fill" color="warn"
    [mat-dialog-close]="true">{{config.confirmButton}}</button>
  }
</mat-dialog-actions>