import { Thing } from "./thing";

export interface VirtualThing {
    id: string;
    name: string;
    description: string;
    separator: string;
    looping: boolean;
    thingId: string;
    defaultMetricPath: string;
    state: VirtualThingState;
    startTimestamp: number;
    startedBy: string;
    filePresent: boolean;
    // enriched
    thing: Thing;
}

export enum VirtualThingState {
    RUNNING = 'RUNNING',
    PAUSED = 'PAUSED',
    STOPPED = 'STOPPED'
}

export class VirtualThingCsvValidationResponse {
    invalidMetricMappingNames: string[];
}