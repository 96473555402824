import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export abstract class ImageService extends ResourceService<Blob> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'images';
    }

    override getById(id: string, extraParams?: HttpParams): Promise<Blob> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getEndpointById(id), { params: params, observe: "response", responseType: "blob" }))
            .then(resp => resp.body);
    }

    override save(content: File, id: string): Promise<Blob> {
        const formData = new FormData();
        formData.append('file', content);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<Blob>(AppConstants.API_BASE_URL + this.getEndpointById(id), formData, { params: params }));
    }
}