import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ThingDefinition } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ActionMenuButtons } from "../shared/components/custom-table/custom-table.component";
import { TreeNode } from "../shared/components/tree-list/tree-list.component";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { FlatTreeNode, FlatTreeService } from "./flat-tree.service";
import { ResourceService } from "./resource.service";
import { TreeService } from "./tree.service";

@Injectable({ providedIn: 'root' })
export class ThingDefinitionService extends ResourceService<ThingDefinition> {

    constructor(
        private treeService: TreeService,
        private flatTreeService: FlatTreeService,
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'thingDefinitions';
    }

    downloadFile(id: string, propId: string): void {
        let params = this.getContextParams();
        firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getFileEnpoint(id, propId), { observe: "response", responseType: "blob", params: params }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, Utilities.getFileNameFromResponse(response.headers, 'file')) });
    }

    deleteFile(id: string, propId: string): Promise<void> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getFileEnpoint(id, propId), { params: params }));
    }

    saveFile(id: string, propId: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getFileEnpoint(id, propId), formData, { params: params }));
    }

    private getFileEnpoint(id: string, propId: string): string {
        return `thingDefinitions/${id}/fileProperties/${propId}`;
    }

    fillTreeNodes(productModelCategories: ThingDefinition[]): TreeNode[] {
        this.treeService.enrichElementsWithActions(productModelCategories, this.getActionMenuElements());
        return this.treeService.fillTreeNodes(productModelCategories, "superThingDefinitionId");
    }

    findAllDescendants(superThingDefinitionId: string, thingDefinitions: ThingDefinition[]): string[] {
        return this.treeService.findAllDescendants(superThingDefinitionId, thingDefinitions, "superThingDefinitionId");
    }

    private getActionMenuElements(): ActionMenuButtons[] {
        let actions = [
            { label: 'Edit', name: 'click', icon: 'edit' },
            { label: 'Extend Thing Definition', name: 'add', icon: 'add' },
            { label: 'Delete', name: 'delete', icon: 'delete', buttonClass: 'warn' }
        ];
        return actions;
    }

    getFlatTree(thingDefinitions: ThingDefinition[]): FlatTreeNode[] {
        const tree = this.fillTreeNodes(thingDefinitions);
        return this.flatTreeService.getFlatTree(tree);
    }

}