import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { VirtualThing, VirtualThingCsvValidationResponse, VirtualThingState } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class VirtualThingService extends ResourceService<VirtualThing> {


    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'virtualThings';
    }

    patchState(id: string, state: VirtualThingState): Promise<VirtualThing> {
        const params = this.getContextParams();
        const body = { state: state, startedBy: this.contextService.getUser().email };
        return firstValueFrom(this.httpClient.patch<VirtualThing>(AppConstants.API_BASE_URL + `${this.getEndpointById(id)}/state`, body, { params: params }));
    }

    downloadFile(id: string): void {
        const params = this.getContextParams();
        firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getFileEnpoint(id), { observe: "response", responseType: "blob", params: params }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, Utilities.getFileNameFromResponse(response.headers, 'file')) });
    }

    deleteFile(id: string): Promise<void> {
        const params = this.getContextParams();
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getFileEnpoint(id), { params: params }));
    }

    saveFile(id: string, file: File): Promise<VirtualThingCsvValidationResponse> {
        const formData = new FormData();
        formData.append('file', file);
        const params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<VirtualThingCsvValidationResponse>(AppConstants.API_BASE_URL + this.getFileEnpoint(id), formData, { params: params }));
    }

    private getFileEnpoint(id: string): string {
        return `${this.getEndpointById(id)}/file`;
    }
}