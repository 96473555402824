import { Metric } from "./metric";
import { Predicate } from "./predicate";
import { ThingDefinition } from "./thing-definition";

export interface Rule {
    id: string;
    name: string;
    thingDefinition: ThingDefinition;
    metric: Metric;
    event: RuleEvent;
    predicate: Predicate;
    value: string;
    actions: RuleAction[];
    canBeDisabled: boolean;
    alertDefinitionId: string;
    originalId: string;
    originalThingDefinitionId: string;
    minDuration: number;
    minDurationTimeUnit: RuleMinDurationTimeUnit;
}

export enum RuleEvent {
    ON_MEASURE_UPDATE = 'ON_MEASURE_UPDATE',
    ON_ALERT_CLEARED = 'ON_ALERT_CLEARED',
    ON_ALERT_ACTIVATED = 'ON_ALERT_ACTIVATED'
}

export enum RuleMinDurationTimeUnit {
    MINUTES = 'MINUTES',
    HOURS = 'HOURS'
}

export interface RuleAction {
    name: string;
    type: RuleActionType;
    callbackUrl: string;
    to: string;
    title: string;
    longMessage: string;
    shortMessage: string;
    severity: RuleActionSeverity;
    basicAuthUsername: string;
    basicAuthPassword: string;
    commandId: string;
    method: RuleActionHttpMethod;
    url: string;
    authorization: RuleActionAuthorizationType;
    username: string;
    password: string;
    headers: RuleActionHttpHeader[];
    payload: string;
}

export enum RuleActionType {
    NOTIFICATION = 'NOTIFICATION',
    REST_API = 'REST_API',
    CALLBACK = 'CALLBACK',
    COMMAND = 'COMMAND'
}

export enum RuleActionSeverity {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    CRITICAL = 'CRITICAL'
}

export enum RuleActionHttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export enum RuleActionAuthorizationType {
    NO_AUTH = 'NO_AUTH',
    BASIC_AUTH = 'BASIC_AUTH',
    MICROSOFT_AZURE_ACTIVE_DIRECTORY = 'MICROSOFT_AZURE_ACTIVE_DIRECTORY'
}

export interface RuleActionHttpHeader {
    name: string;
    value: string;
}