import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PRIVACY_POLICY_NAME, TERMS_OF_SERVICE_NAME, UserAgreementDocument } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class UserAgreementDocumentService extends ResourceService<UserAgreementDocument> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'userAgreementDocuments';
    }

    patchByName(name: string, body: any): Promise<void> {
        let params = this.getContextParams().set('name', name);
        return firstValueFrom(this.httpClient.patch<void>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: params }));
    }

    static isDefault(doc: UserAgreementDocument): boolean {
        return [PRIVACY_POLICY_NAME, TERMS_OF_SERVICE_NAME].includes(doc?.name);
    }

}