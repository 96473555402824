import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { LocationMetric } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { AlgorithmTestOutput } from "./metric.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class LocationMetricService extends ResourceService<LocationMetric> {

    private LOCAL_STORAGE_TRY_INSIGHT_LOCATION_METRIC = 'servitly-console-try-insight-location-metric-';

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'locationMetrics';
    }

    getAlgorithm(id: string): Promise<string> {
        let params = this.getContextParams();
        return firstValueFrom(
            this.httpClient.get(AppConstants.API_BASE_URL + this.getAlgoirithmEnpoint(id), { params: params, observe: "response", responseType: "blob" })
        ).then(response => response.body.text());
    }

    saveAlgorithm(id: string, content: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', new File([content], 'content'));
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getAlgoirithmEnpoint(id), formData, { params: params }));
    }

    import(file: File, category: string, delimiter: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = new HttpParams().set('category', category).set('delimiter', delimiter);
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/import`, formData, { params: params }));
    }

    export(category: string): Promise<void> {
        let params = new HttpParams().set('category', category);
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + `${this.getEndpoint()}/export`, { params: params, observe: "response", responseType: "blob" }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, 'location_metrics_export.csv') });
    }

    private getAlgoirithmEnpoint(id: string): string {
        return this.getEndpoint() + `/${id}/algorithm`;
    }

    testAlgorithm(id: string, locationId: string, execute: boolean): Promise<AlgorithmTestOutput> {
        let params = this.getContextParams(new HttpParams().set('locationId', locationId).set('execute', execute));
        return firstValueFrom(this.httpClient.post<AlgorithmTestOutput>(AppConstants.API_BASE_URL + `${this.getEndpointById(id)}/test`, {}, { params: params }));
    }

    saveFormValueInStorage(formValue: any): void {
        let storedFormObj = this.getFormValueInStorage();
        storedFormObj = formValue;
        localStorage.setItem(this.LOCAL_STORAGE_TRY_INSIGHT_LOCATION_METRIC + this.contextService.getEnvironment().id, JSON.stringify(storedFormObj));
    }

    getFormValueInStorage(): any {
        let storedFormValues = localStorage.getItem(this.LOCAL_STORAGE_TRY_INSIGHT_LOCATION_METRIC + this.contextService.getEnvironment().id);
        return storedFormValues ? JSON.parse(storedFormValues) : {};
    }

    getPathFromCategory(metricCategory: LocationMetricCategory): string {
        switch (metricCategory) {
            case LocationMetricCategory.INSIGHT:
                return 'insights';
            case LocationMetricCategory.RAW:
                return 'raw-data';
            default:
                return '';
        }
    }

}

export enum LocationMetricCategory {
    RAW = 'RAW',
    INSIGHT = 'INSIGHT'
}
