import { HttpHeaders } from "@angular/common/http";
import { SelectOption } from "../../models";

export class Utilities {

    static getFileNameFromResponse(headers: HttpHeaders, defaultName: string): string {
        let disposition: string = headers.has('content-disposition') ? headers.get('content-disposition') : "";
        if (disposition) {
            disposition = (disposition as any).replaceAll('"', '');
        }
        var startIndex = disposition.indexOf('filename=') + 9;
        return startIndex >= 0 ? disposition.substring(startIndex) : defaultName;
    }


    static wrapFileAndDownload(file: Blob, fileName: string): void {
        this.downlodFileFromUrl(window.URL.createObjectURL(file), fileName);
    }

    static downlodFileFromUrl(url: string, name?: string): void {
        let link = document.createElement('a');
        link.href = url;
        if (name) {
            link.download = name;
        }
        document.body.appendChild(link);    //needed for firefox
        link.click();
        document.body.removeChild(link);    //needed for firefox
    }

    static mapValuesToSelectOptions(values: string[]): SelectOption[] {
        return values.map(p => { return { label: p, value: p } });
    }

    static mapEnumToSelectOptions(enums: any): SelectOption[] {
        return this.mapValuesToSelectOptions(Object.values(enums));
    }

    static mapObjectMapToSelectOptions(map: { [key: string]: string }): SelectOption[] {
        return Object.keys(map).map(key => { return { label: map[key], value: key } });
    }

    static toCamelCase(str: string): string {
        let string = str.replace(/[^a-z0-9]/gi, ' ').replace(/(?:(\s+.))/g, function (match) {
            return match.charAt(match.length - 1).toUpperCase();
        }).trim();
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    static toTitleCase(str: string): string {
        if (!str) {
            return str;
        }
        return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
    }

    static isEmpty(value: any): boolean {
        return value === undefined || value === null || value === '';
    }

    static formatBytes(bytes: number, precision: number = 1): string {
        if (isNaN(bytes)) {
            return '';
        }
        let units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
        let unitIndex = 0;
        while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
            if (unitIndex == 5) {
                break;
            }
        }
        return (unitIndex ? bytes.toFixed(precision) : bytes) + ' ' + units[unitIndex];
    }

    static truncateString(str: string, maxSize: number = 30): string {
        if (str) {
            if (str.length > maxSize) {
                return str.substring(0, maxSize) + '...';
            } else {
                return str;
            }
        }
        return "";
    }

    static extractMessageFromError(err: any): string {
        let message = err?.error?.message || err?.error?.error;
        if (!message) {
            try {
                let messageObj = JSON.parse(err?.error);
                message = messageObj.message || messageObj.error;
            } catch {
                // ignore
            }
        }
        return message;
    }

    static toAcronym(str: string): string {
        if (!str) {
            return str;
        }
        return str.split(/\s/).map(parts => parts[0]).join('');
    }

    static isMongoId(id: string): boolean {
        return new RegExp(/^[a-f\d]{24}$/i).test(id);
    }

    static getDateForFileName(): string {
        const now = new Date();
        return `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
    }

    static getCsvFileDelimiter(file: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let delimiter = ',';
            reader.onload = () => {
                var text = reader.result;
                var firstLine = (text as string).split('\n').shift(); // first line 
                if (firstLine.includes(';')) {
                    delimiter = ';';
                }
            }
            reader.onloadend = () => {
                resolve(delimiter)
            }
            reader.onerror = reject;
            reader.readAsText(file, 'UTF-8');
        });
    }
}