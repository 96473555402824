import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Template } from "../models";
import { FilterDefinition, WidgetDefinition } from "../private-area/environment-area/template/template-editor/template-editor.config";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class TemplateService extends ResourceService<Template> {

	constructor(
		httpClient: HttpClient,
		contextService: ContextService
	) { super(httpClient, contextService); }

	protected getEndpoint(): string {
		return 'templates';
	}

	getContent(id: string): Promise<string> {
		let params = this.getContextParams();
		return firstValueFrom(
			this.httpClient.get(AppConstants.API_BASE_URL + this.getContentEnpoint(id), { params: params, observe: "response", responseType: "blob" })
		).then(response => response.body.text());
	}

	saveContent(id: string, content: string): Promise<string> {
		const formData = new FormData();
		formData.append('file', new File([content], 'content'));
		let params = this.getContextParams();
		return firstValueFrom(this.httpClient.post<string>(AppConstants.API_BASE_URL + this.getContentEnpoint(id), formData, { params: params }));
	}

	private getContentEnpoint(id: string): string {
		return `${this.getEndpoint()}/${id}/content`;
	}

	getTemplateEditorConfigurations(extraParams: HttpParams): Promise<{ categories: string[], components: WidgetDefinition[], filters: FilterDefinition[] }> {
		let params = this.getContextParams(extraParams);
		return firstValueFrom(this.httpClient.get<{ categories: string[], components: WidgetDefinition[], filters: FilterDefinition[] }>(AppConstants.API_BASE_URL + 'templateEditorConfigurations', { params: params }));
	}

}
