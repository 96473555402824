import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const LOCAL_STORAGE_TOKEN_KEY = 'servitly-console-' + TOKEN_KEY;

@Injectable({ providedIn: 'root' })
export class TokenStorageService {

	signOut(): void {
		sessionStorage.removeItem(TOKEN_KEY);
		localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
	}

	saveToken(token: string): void {
		sessionStorage.removeItem(TOKEN_KEY);
		sessionStorage.setItem(TOKEN_KEY, token);
		localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
		localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
	}

	getToken(): string {
		return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY) || '';
	}

}