import { CustomerType } from "./customer";
import { InsightAggregationPeriod } from "./insight-config";
import { CountableType, DictionaryItem, Metric, ValueRange } from "./metric";
import { ThingDefinition } from "./thing-definition";

export interface PropertyDefinition {
    id: string;
    name: string;
    label: string;
    description: string;
    type: PropertyType;
    minValue: number;
    maxValue: number;
    stepValue: number;
    mandatory: boolean;
    unique: boolean;
    value: string;
    values: PropertyDefinitionDictionaryValue[];
    selectionMode: PropertySelectionMode;
    notifyOnChange: boolean;
    order: number;
    group: string;
    unit: string;
    userTypeFiltered: boolean;
    userTypeIds: string[];
    predefined: boolean;
    fileContentType: FilePropertyContentType;
    countableType: CountableType;
    ranges: ValueRange[];
    dictionary: DictionaryItem[];
    algorithmsInsightBinding: AlgorithmsInsightBinding;
    statisticBinding: PropertyDefinitionStatisticBinding;
    regularExpression: string;
    validationErrorMessage: string;
    // thing
    restoreOnDeactivation: boolean;
    thingDefinition: ThingDefinition;
    metric: Metric;
    // customer
    customerType: CustomerType;
    hideFromUserRegistrationPage: boolean;
    searchable: boolean;
    // user
    providedDuringActivation: boolean;
    // maintenance
    maintenanceWorkTypes: string[];
    // location default
    derivedValue: boolean;
    valueExpression: string;
    // task
    taskDefinitionId: string;
    taskPropertyDefinitionId: string;
    visibilityCondition: string;
}

export enum PropertyType {
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DOUBLE = 'DOUBLE',
    EMAIL = 'EMAIL',
    FLOAT = 'FLOAT',
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    PHONE_NUMBER = 'PHONE_NUMBER',
    MOBILE_NUMBER = 'MOBILE_NUMBER',
    STRING = 'STRING',
    CONTACTS = 'CONTACTS',
    FILE = 'FILE',
    STATE = 'STATE',
    CITY = 'CITY',
    ADDRESS = 'ADDRESS',
    ZIP_CODE = 'ZIP_CODE',
    STREET_NUMBER = 'STREET_NUMBER',
    FISCAL_CODE = 'FISCAL_CODE',
    OBJECT_ARRAY = 'OBJECT_ARRAY'
}

export enum PropertySelectionMode {
    RADIO_BUTTON = 'RADIO_BUTTON',
    SELECTION = 'SELECTION'
}

export interface PropertyDefinitionDictionaryValue {
    value: string;
    label: string;
}

export enum PropertyDefinitionEntity {
    THING = 'THING',
    THING_DEFINITION = 'THING_DEFINITION',
    LOCATION = 'LOCATION',
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER',
    USER = 'USER',
    MAINTENANCE_WORK = 'MAINTENANCE_WORK',
    TASK = 'TASK'
}

export enum FilePropertyContentType {
    IMAGE = 'IMAGE',
    ANY = 'ANY'
}

export interface AlgorithmsInsightBinding {
    insightId: string;
    output: string;
    period: InsightAggregationPeriod;
}

export interface PropertyDefinitionStatisticBinding {
    resource: PropertyDefinitionStatisticResource;
    aggregation: PropertyDefinitionStatisticAggregation;
    aggregationProperty: string;
    period: PropertyDefinitionStatisticPeriod;
    activationType: PropertyDefinitionStatisticEventActivationType;
    conditions: PropertyDefinitionStatisticCondition[];
}

export enum PropertyDefinitionStatisticResource {
    EVENT = 'EVENT',
    THING = 'THING',
    LOCATION = 'LOCATION',
    CUSTOMER = 'CUSTOMER',
    USER = 'USER'
}

export enum PropertyDefinitionStatisticAggregation {
    COUNT = 'COUNT',
    SUM = 'SUM',
    MIN = 'MIN',
    MAX = 'MAX',
    AVG = 'AVG'
}

export enum PropertyDefinitionStatisticPeriod {
    THIS_YEAR = 'THIS_YEAR',
    THIS_MONTH = 'THIS_MONTH',
    LAST_MONTH = 'LAST_MONTH',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_24_HOURS = 'LAST_24_HOURS'
}

export enum PropertyDefinitionStatisticEventActivationType {
    ACTIVE = 'ACTIVE',
    ACTIVATED = 'ACTIVATED'
}

export interface PropertyDefinitionStatisticCondition {
    property: string;
    predicate: PropertyDefinitionStatisticConditionPredicate;
    value: any;
}

export enum PropertyDefinitionStatisticConditionPredicate {
    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    IS_EMPTY = 'IS_EMPTY',
    IS_NOT_EMPTY = 'IS_NOT_EMPTY',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
    BEGINS_WITH = 'BEGINS_WITH',
    ENDS_WITH = 'ENDS_WITH',
    LESS_THAN = 'LESS_THAN',
    GREATER_THAN = 'GREATER_THAN',
    IN = 'IN'
}