import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Tenant } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class TenantService extends ResourceService<Tenant> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'tenant';
    }

    getByContext(): Promise<Tenant> {
        return firstValueFrom(this.httpClient.get<Tenant>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: this.getContextParams() }));
    }

    override save(body: any): Promise<Tenant> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.patch<void>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: params })).then(() => this.getByContext());
    }

    saveCertificate(file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/domainAliasesSslCertificate', formData, { params: params }));
    }

    deleteCertificate(): Promise<void> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/domainAliasesSslCertificate', { params: params }));
    }

    saveCertificateKey(file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/domainAliasesSslCertificateKey', formData, { params: params }));
    }

    deleteCertificateKey(): Promise<void> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/domainAliasesSslCertificateKey', { params: params }));
    }


}