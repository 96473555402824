import { ThingDefinition } from "./thing-definition";

export interface ThingDefinitionReport extends Report {
    thingDefinition: ThingDefinition;
}

export interface NetworkReport extends Report {
    type: NetworkReportType;
}

export interface Report {
    id: string;
    name: string;
    description: string;
    filename: string;
    title: string;
    frequency: ReportFrequency;
    dayOfWeek: DayOfWeek;
    dayOfMonth: number;
    emailSubject: string;
    emailBody: string;
    templateName: string;
}

export enum NetworkReportType {
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER'
}

export enum ReportFrequency {
    NONE = 'NONE',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY'
}

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
}
