import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class EventService extends ResourceService<Event> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'events';
    }

    getExport(startTimestamp: number, endTimestamp: number): Promise<{ url: string }> {
        let params = this.getContextParams();
        params = params.set('startTimestamp', startTimestamp).set('endTimestamp', endTimestamp);
        return firstValueFrom(this.httpClient.get<{ url: string }>(AppConstants.API_BASE_URL + this.getFileEnpoint(), { params: params }));
    }

    downloadFile(exportUrl: string): Promise<void> {
        return firstValueFrom(this.httpClient.get(exportUrl, { observe: 'response', responseType: 'blob' }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, 'audit_export.csv') });
    }

    private getFileEnpoint(): string {
        return this.getEndpoint() + '/export';
    }

}

/* empty class only for export, move to a file if enriched with fields */
class Event { }
