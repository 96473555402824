export interface ApiKey {
    id: string;
    name: string;
    key: string;
    restriction: RestrictionType;
    domainName: string;
    appId: string;
    permissions: string[];
    secret: string;
    maxRequestCount: number;
    maxRequestTimeUnit: PublishTimeUnit;
    singleMobileDevice: boolean;
    jwtAsCookie: boolean;
}

export enum RestrictionType {
    NONE = 'NONE',
    WEB_SITE = 'WEB_SITE',
    MOBILE_APP = 'MOBILE_APP'
}
export enum PublishTimeUnit {
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS'
}