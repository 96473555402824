import { Component, HostListener, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MaterialModule } from "../../../material.module";
import { NoSanitizePipe } from "../../pipe/no-sanitazion.pipe";
import { Utilities } from "../../utilities/utilities";

@Component({
    standalone: true,
    selector: 'resource-conflict-dialog',
    imports: [MaterialModule, NoSanitizePipe],
    templateUrl: './resource-conflict-dialog.component.html',
    styles: [`
        mat-dialog-content > mat-icon {
            font-size: 80px;
            height: 80px;
            width: 80px;
            margin-top:16px;
            margin-bottom: 32px;
        }
        mat-dialog-content .sy-body-1 {
            margin-bottom: 32px;
            line-height: 22px;
        }
    `]
})
export class ResourceConflictDialog {

    downloaded: boolean;

    private fileName: string;
    private fileContent: string;

    constructor(
        private dialogRef: MatDialogRef<ResourceConflictDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.fileName = data.fileName;
        this.fileContent = data.fileContent;
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.dialogRef.close(false);
        } else if (event.key === 'Enter') {
            this.dialogRef.close(true);
        }
    }

    download(): void {
        Utilities.wrapFileAndDownload(new Blob([this.fileContent]), this.fileName);
        this.downloaded = true;
    }
}