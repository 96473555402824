import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Customer, Location, Organization, Partner, UserType, UserView } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { CustomerService } from "./customer.service";
import { LocationService } from "./location.service";
import { OrganizationService } from "./organization.service";
import { PartnerService } from "./partner.service";
import { ResourceService } from "./resource.service";
import { UserTypeService } from "./user-type.service";

@Injectable({ providedIn: 'root' })
export class UserViewService extends ResourceService<UserView> {

    constructor(
        private userTypeService: UserTypeService,
        private organizationService: OrganizationService,
        private partnerService: PartnerService,
        private customerService: CustomerService,
        private locationService: LocationService,
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'userViews';
    }

    override getRecursivelyAll(extraParams?: HttpParams): Promise<UserView[]> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.get<UserView[]>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    navigate(userView: UserView): Promise<{ url: string }> {
        let params = this.getContextParams();
        if (userView) {
            params = params.set('userViewId', userView.id).set('language', navigator.language?.substring(0, 2) || 'en').set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC');
        }
        return firstValueFrom(this.httpClient.post<{ url: string }>(AppConstants.API_BASE_URL + this.getEndpoint() + '/navigate', {}, { params: params }));
    }

    enrichUserViews(userViews: UserView[]): void {
        // asynchronously enriches userViews with network & userType details
        if (userViews?.length) {
            let userTypeCache: { [id: string]: UserType } = {};
            let organizationCache: { [id: string]: Organization } = {};
            let partnerCache: { [id: string]: Partner } = {};
            let customerCache: { [id: string]: Customer } = {};
            let locationCache: { [id: string]: Location } = {};
            for (let userView of userViews) {
                this.userTypeService.getById(userView.userTypeId).then(ut => {
                    userView.userType = ut;
                    userTypeCache[ut.id] = ut;
                }).catch(() => userView.invalidUserType = true);
                if (userView.organizationId) {
                    this.organizationService.getById(userView.organizationId).then(o => {
                        userView.organization = o;
                        organizationCache[o.id] = o;
                    }).catch(() => userView.invalidNetworkItem = true);
                } else if (userView.partnerId) {
                    this.partnerService.getById(userView.partnerId).then(o => {
                        userView.partner = o;
                        partnerCache[o.id] = o;
                    }).catch(() => userView.invalidNetworkItem = true);
                } else if (userView.customerId) {
                    this.customerService.getById(userView.customerId).then(c => {
                        userView.customer = c;
                        customerCache[c.id] = c;
                    }).catch(() => userView.invalidNetworkItem = true);
                    if (userView.locationId) {
                        this.locationService.getById(userView.locationId).then(l => {
                            userView.location = l;
                            locationCache[l.id] = l;
                        }).catch(() => userView.invalidNetworkItem = true);
                    }
                }
            }
        }
    }
}