import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Project, ProjectBundleConfigurationSet } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ProjectService extends ResourceService<Project> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'projects';
    }

    getBundleConfigSet(): Promise<ProjectBundleConfigurationSet> {
        let params: HttpParams = this.getContextParams();
        return firstValueFrom(this.httpClient.get<ProjectBundleConfigurationSet>(AppConstants.API_BASE_URL + 'planConfigurations', { params: params }))
    }

    patchProject(id: any, body: { platform: string, modules: string[] }): Promise<void> {
        return firstValueFrom(this.httpClient.patch<void>(AppConstants.API_BASE_URL + this.getEndpointById(id), body, { params: this.getContextParams() }));
    }
}