import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Recipe } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class RecipeService extends ResourceService<Recipe> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'recipes';
    }

    importFile(id: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/${id}/import`, formData, { params: params }));
    }
}