import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ActionDefinition } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ActionDefinitionService extends ResourceService<ActionDefinition> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'actionDefinitions';
    }

    saveIcon(actionDefinitionId: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getMenuIconEndpoint(actionDefinitionId), formData, { params: params }));
    }

    deleteIcon(actionDefinitionId: string): Promise<void> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getMenuIconEndpoint(actionDefinitionId), { params: params }));
    }

    private getMenuIconEndpoint(actionDefinitionId: string): string {
        return this.getEndpoint() + '/' + actionDefinitionId + '/icon';
    }
}