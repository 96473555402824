import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConnectionMapping } from "../models";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";
import { firstValueFrom } from "rxjs";
import { AppConstants } from "../shared/app.constants";

@Injectable({ providedIn: 'root' })
export class IotConnectorService extends ResourceService<ConnectionMapping> {

	constructor(
		httpClient: HttpClient,
		contextService: ContextService
	) { super(httpClient, contextService); }

	protected getEndpoint(): string {
		return 'tenantMapping';
	}

	get(): Promise<ConnectionMapping> {
		let params = this.getContextParams();
		return firstValueFrom(this.httpClient.get<ConnectionMapping>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
	}

	override save(body: any): Promise<ConnectionMapping> {
		let params = this.getContextParams();
		return firstValueFrom(this.httpClient.put<ConnectionMapping>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: params }));
	}

	saveTruststoreCertificate(file: File): Promise<void> {
		const formData = new FormData();
		formData.append('file', file);
		let params = this.getContextParams();
		return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/truststoreCertificate', formData, { params: params }));
	}

	deleteTruststoreCertificate(): Promise<void> {
		let params = this.getContextParams();
		return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/truststoreCertificate', { params: params }));
	}

	saveKeystoreCertificate(file: File): Promise<void> {
		const formData = new FormData();
		formData.append('file', file);
		let params = this.getContextParams();
		return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/keystoreCertificate', formData, { params: params }));
	}

	deleteKeystoreCertificate(): Promise<void> {
		let params = this.getContextParams();
		return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/keystoreCertificate', { params: params }));
	}

}
