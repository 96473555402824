import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { User } from "../models/user";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { NavigationService } from "./navigation.service";
import { TokenStorageService } from "./token-storage.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

	constructor(
		private httpClient: HttpClient,
		private tokenService: TokenStorageService,
		private navigationService: NavigationService,
		private contextService: ContextService
	) { }

	login(body: any): Promise<void> {
		return firstValueFrom(this.httpClient.post<LoginResponse>(AppConstants.API_BASE_URL + 'auth/login', body))
			.then(resp => this.tokenService.saveToken(resp.accessToken));
	}

	logout(extras?: any): void {
		this.tokenService.signOut();
		this.contextService.setUser(null);
		this.navigationService.goToLoginPage(extras);
	}

	activate(body: any): Promise<void> {
		return firstValueFrom(this.httpClient.post<LoginResponse>(AppConstants.API_BASE_URL + 'auth/activate', body))
			.then(resp => this.tokenService.saveToken(resp.accessToken));
	}

	forgotPassword(body: any): Promise<void> {
		return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + 'auth/passwordReset', body));
	}

	resetPassword(body: any): Promise<void> {
		return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + 'auth/passwordReset', body));
	}

}

export interface LoginResponse {
	accessToken: string,
	user: User
}
