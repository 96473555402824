import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Environment, EnvironmentCreationConfigurationSet, EnvironmentType } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class EnvironmentService extends ResourceService<Environment> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'environments';
    }

    static getTypeIcon(type: EnvironmentType): string {
        switch (type) {
            case EnvironmentType.PRODUCTION:
                return 'precision_manufacturing';
            case EnvironmentType.DEVELOP:
                return 'code_blocks';
            case EnvironmentType.SANDBOX:
                return 'inbox';
            case EnvironmentType.STAGING:
                return 'preview';
            case EnvironmentType.TEST:
            case EnvironmentType.TEST2:
            case EnvironmentType.TEST3:
                return 'generating_tokens';
            default:
                return '';
        }
    }

    static getStatusIcon(e: Environment): string {
        let icon;
        let clazz;
        switch (e.configurationStatus) {
            case 'OK':
                icon = 'check_circle';
                clazz = 'text-success';
                break;
            case 'ERROR':
                icon = 'error';
                clazz = 'text-danger';
                break;
            default: // EMPTY
                icon = 'autorenew';
                clazz = 'spinning-image';
                break;
        }
        return `<span class="material-icons ${clazz}">${icon}</span>`;
    }

    configure(id: string, body: any): Promise<HttpResponse<any>> {
        return firstValueFrom(this.httpClient.post(AppConstants.API_BASE_URL + this.getEndpointById(id) + '/configure', body, { observe: "response" }))
    }

    getConfigParameters(): Promise<{ segments: EnvironmentConfigObj[], thingDefinitions: EnvironmentConfigObj[] }> {
        return firstValueFrom(this.httpClient.get<{ segments: EnvironmentConfigObj[], thingDefinitions: EnvironmentConfigObj[] }>(AppConstants.API_BASE_URL + 'environmentConfigurationParameters'))
    }

    getCreationConfigSet(): Promise<EnvironmentCreationConfigurationSet> {
        let params: HttpParams = this.getContextParams();
        return firstValueFrom(this.httpClient.get<EnvironmentCreationConfigurationSet>(AppConstants.API_BASE_URL + 'environmentCreationConfigurationSet', { params: params }))
    }
}

export interface EnvironmentConfigObj {
    name: string,
    label: string
} 