import { SelectOption } from "../../../models";

export class DynamicFormService {

    static newCheckboxField(name: string, label: string, group: string, required: boolean, description?: string): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required, description);
        field.type = DynamicFormFieldType.CHECKBOX;
        return field;
    }

    static newRadioButtonField(name: string, label: string, group: string, required: boolean, values: { value: string, label: string }[]): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required);
        field.type = DynamicFormFieldType.RADIO_BUTTON;
        field.values = values;
        return field;
    }

    static newDateField(name: string, label: string, group: string, required: boolean, description?: string): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required, description);
        field.type = DynamicFormFieldType.DATE;
        return field;
    }

    static newFileField(name: string, label: string, group: string, required: boolean, description?: string): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required, description);
        field.type = DynamicFormFieldType.FILE;
        return field;
    }

    static newNumberField(name: string, label: string, group: string, required: boolean, min?: number, max?: number, step?: number, description?: string): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required, description);
        field.type = DynamicFormFieldType.NUMBER;
        field.min = min;
        field.max = max;
        field.step = step;
        return field;
    }

    static newSelectionField(name: string, label: string, group: string, required: boolean, values: { value: string, label: string }[], description?: string): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required);
        field.type = DynamicFormFieldType.SELECTION;
        field.values = values;
        return field;
    }

    static newStringField(name: string, label: string, group: string, required: boolean, description?: string): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required, description);
        field.type = DynamicFormFieldType.STRING;
        return field;
    }

    static newMetricField(name: string, label: string, group: string, required: boolean, description: string, thingDefinitionSelector: boolean = false): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required, description);
        field.type = DynamicFormFieldType.METRIC;
        field.thingDefinitionSelector = thingDefinitionSelector;
        return field;
    }

    static newLocationMetricField(name: string, label: string, group: string, required: boolean, description: string): DynamicFormField {
        let field = new DynamicFormField(name, label, group, required, description);
        field.type = DynamicFormFieldType.LOCATION_METRIC;
        return field;
    }

}

export class DynamicFormField {
    type: DynamicFormFieldType;
    name: string;
    label: string;
    group: string;
    description: string;
    required: boolean;
    values: SelectOption[];
    min: number;
    max: number;
    step: number;
    selectionEndpoint: string;
    selectionValueField: string;
    selectionLabelField: string;
    defaultValue: any;
    readOnly: boolean;
    keyValueFields: [string, string];
    keyValuePlaceholders: [string, string];
    valueOnly: boolean;
    selectValues: string[] | SelectOption[];
    basicUsernameProperty: string;
    basicPasswordProperty: string;
    defaultValues: [any, any];
    thingDefinitionSelector: boolean;

    constructor(name: string, label: string, group: string, required: boolean, description?: string) {
        this.name = name;
        this.label = label;
        this.group = group;
        this.required = required;
        this.description = description;
    }
}

export enum DynamicFormFieldType {
    AUTHENTICATION = 'AUTHENTICATION',
    CHECKBOX = 'CHECKBOX',
    COMMAND = 'COMMAND',
    CONFIGURATION_PARAMETER = 'CONFIGURATION_PARAMETER',
    DATE = 'DATE',
    FILE = 'FILE',
    KEY_VALUE = 'KEY_VALUE',
    LOCATION_METRIC = 'LOCATION_METRIC',
    METRIC = 'METRIC',
    NUMBER = 'NUMBER',
    PASSWORD = 'PASSWORD',
    RADIO_BUTTON = 'RADIO_BUTTON',
    SELECTION = 'SELECTION',
    STRING = 'STRING',
    STRING_ARRAY = 'STRING_ARRAY'
}
