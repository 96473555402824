import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Firmware } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class FirmwareService extends ResourceService<Firmware> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'firmwares';
    }

    downloadFile(id: string): void {
        let params = this.getContextParams();
        firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getFileEnpoint(id), { observe: "response", responseType: "blob", params: params }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, Utilities.getFileNameFromResponse(response.headers, 'file')) });
    }

    deleteFile(id: string): Promise<void> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getFileEnpoint(id), { params: params }));
    }

    saveFile(id: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getFileEnpoint(id), formData, { params: params }));
    }

    private getFileEnpoint(id: string): string {
        return this.getEndpoint() + '/' + id + '/content';
    }

}