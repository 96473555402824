import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServiceLevel } from "../models";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ServiceLevelService extends ResourceService<ServiceLevel> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'serviceLevels';
    }

}