import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class DynamicService extends ResourceService<any> {

    private dynamicEndpoint: string;

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return this.dynamicEndpoint;
    }

    setDynamicEndpoint(dynamicEndpoint: string): void {
        this.dynamicEndpoint = dynamicEndpoint;
    }

}