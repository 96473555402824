import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProductModelCategory } from "../models";
import { ActionMenuButtons } from "../shared/components/custom-table/custom-table.component";
import { TreeNode } from "../shared/components/tree-list/tree-list.component";
import { ContextService } from "./context.service";
import { FlatTreeNode, FlatTreeService } from "./flat-tree.service";
import { ResourceService } from "./resource.service";
import { TreeService } from "./tree.service";

@Injectable({ providedIn: 'root' })
export class ProductModelCategoryService extends ResourceService<ProductModelCategory> {

    private treeNodeIcon = "communities";

    constructor(
        private flatTreeService: FlatTreeService,
        private treeService: TreeService,
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'productModelCategories';
    }

    findAllDescendants(categoryId: string, productModelCategories: ProductModelCategory[]): string[] {
        return this.treeService.findAllDescendants(categoryId, productModelCategories, "parentId");
    }

    private getActionMenuElements(): ActionMenuButtons[] {
        let actions = [
            { label: 'Edit', name: 'click_product_model_category', icon: 'edit' },
            { label: 'Add Category', name: 'add_category', icon: 'add' },
            { label: 'Add Product Model', name: 'add_product_model', icon: 'add' },
            { label: 'Delete', name: 'delete_product_model_category', icon: 'delete', buttonClass: 'warn' }
        ];
        return actions;
    }

    fillTreeNodes(productModelCategories: ProductModelCategory[]): TreeNode[] {
        this.treeService.enrichElementsWithActions(productModelCategories, this.getActionMenuElements());
        return this.treeService.fillTreeNodes(productModelCategories, "parentId", this.treeNodeIcon, "fill");
    }

    getFlatTree(productModelCategories: ProductModelCategory[]): FlatTreeNode[] {
        const tree = this.fillTreeNodes(productModelCategories);
        return this.flatTreeService.getFlatTree(tree);
    }

}