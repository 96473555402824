import { Component, HostListener, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MaterialModule } from "../../../material.module";
import { NoSanitizePipe } from "../../pipe/no-sanitazion.pipe";

@Component({
    standalone: true,
    selector: 'complex-confirm-dialog',
    imports: [MaterialModule, NoSanitizePipe],
    templateUrl: './complex-confirm-dialog.component.html',
    styles: [`
        mat-dialog-content mat-icon {
            font-size: 80px;
            height: 80px;
            width: 80px;
            color: #bdbdbd;
            margin-top:16px;
            margin-bottom: 32px;
        }
        mat-dialog-content .sy-body-1 {
            margin-bottom: 32px;
            line-height: 22px;
        }
    `]
})
export class ComplexConfirmDialog {

    config: ComplexConfirmDialogConfig;

    constructor(
        private dialogRef: MatDialogRef<ComplexConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.config = data.config;
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.dialogRef.close(false);
        } else if (event.key === 'Enter') {
            this.dialogRef.close(true);
        }
    }
}

export class ComplexConfirmDialogConfig {
    title: string;
    subtitle: string;
    icon: string;
    message: string;
    closeButton?: string;
    confirmButton?: string;
    html?: string;
}