import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Report } from "../models";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ReportService extends ResourceService<Report> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        if (this.contextService.isGeneralContext()) {
            return 'networkReports';
        } else {
            return 'thingDefinitionReports';
        }
    }
}