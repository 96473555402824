import { Condition } from "./condition";

export interface ActionDefinition {
    id: string;
    name: string;
    description: string;
    thingDefinitionId: string;
    topic: ActionTopic;
    priority: ActionPriority;
    iconUrl: string;
    event: ActionEvent;
    alertDefinitionIds: string[];
    duePeriodTime: number;
    duePeriodTimeUnit: ActionDuePeriodTimeUnit;
    userTypeFiltered: boolean;
    userTypeIds: string[];
    activationType: ActionActivationType;
    period: ActionActivationPeriod;
    periodCount: number;
    dayOfMonth: number;
    technicalDescription: String;
    reschedulable: boolean;
    doneCondition: Condition;
    discardedCondition: Condition;
    dayOfWeek: number;
}

export enum ActionTopic {
    CONSUMABLES = 'CONSUMABLES',
    PERFORMANCE = 'PERFORMANCE',
    MAINTENANCE = 'MAINTENANCE',
    OPERATIONS = 'OPERATIONS'
}

export enum ActionPriority {
    LOW = 'LOW',
    NORMAL = 'NORMAL',
    HIGH = 'HIGH'
}

export enum ActionEvent {
    ON_ALERT_ACTIVATED = 'ON_ALERT_ACTIVATED'
}

export enum ActionDuePeriodTimeUnit {
    DAYS = 'DAYS',
    MONTHS = 'MONTHS'
}

export enum ActionActivationType {
    EVENT = 'EVENT',
    PERIOD = 'PERIOD'
}

export enum ActionActivationPeriod {
    DAILY = 'DAILY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY'
}
