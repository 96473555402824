import { AlertDefinition } from "./alert-definition";
import { Rule } from "./rule";

export interface Option {
    id: string;
    name: string;
    alertDefinition: AlertDefinition;
    rule: Rule;
    label: string;
    description: string;
    type: OptionType;
    minValue: string;
    maxValue: string;
    mandatory: boolean;
    value: string;
    values: string[];
    selectionMode: OptionSelectionMode;
    multipleValues: boolean;
    separator: string;
}

export enum OptionType {
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DOUBLE = 'DOUBLE',
    EMAIL = 'EMAIL',
    FLOAT = 'FLOAT',
    INTEGER = 'INTEGER',
    STRING = 'STRING'
}

export enum OptionSelectionMode {
    RADIO_BUTTON = 'RADIO_BUTTON',
    SELECTION = 'SELECTION'
}