import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Algorithm, AlgorithmInput, AlgorithmInputType } from "../models";
import { AppConstants } from "../shared/app.constants";
import { DynamicFormField, DynamicFormService } from "../shared/components/dynamic-form/dynamic-form.service";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class AlgorithmService extends ResourceService<Algorithm> {

    private LOCAL_STORAGE_TRY_ALGORITHM = 'servitly-console-try-alogorihtm-';

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'algorithms';
    }

    getScript(id: string): Promise<string> {
        let params = this.getContextParams();
        return firstValueFrom(
            this.httpClient.get(AppConstants.API_BASE_URL + this.getScriptEndpoint(id), { params: params, observe: "response", responseType: "blob" })
        ).then(response => response.body.text());
    }

    saveScript(id: string, content: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', new File([content], 'content'));
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getScriptEndpoint(id), formData, { params: params }));
    }

    private getScriptEndpoint(id: string) {
        return `${this.getEndpointById(id)}/script`;
    }

    test(id: string, body: any, execute: boolean, customerId: string, locationId: string, thingId?: string, historicalWorkSessionId?: string, datasetRange?: string, usageUnit?: string, standardUsage?: string, tolerance?: string): Promise<AlgorithmTestOutput> {
        let params = this.getContextParams(new HttpParams().set('customerId', customerId).set('locationId', locationId).set('execute', execute));
        if (thingId) {
            params = params.set('thingId', thingId);
        }
        if (historicalWorkSessionId) {
            params = params.set('historicalWorkSessionId', historicalWorkSessionId);
        }
        if (datasetRange) {
            params = params.set('datasetRange', datasetRange);
        }
        if (usageUnit) {
            params = params.set('usageUnit', usageUnit);
        }
        if (standardUsage) {
            params = params.set('standardUsage', standardUsage);
        }
        if (tolerance) {
            params = params.set('tolerance', tolerance);
        }
        return firstValueFrom(this.httpClient.post<AlgorithmTestOutput>(AppConstants.API_BASE_URL + `${this.getEndpointById(id)}/test`, body, { params: params }));
    }

    saveFormValueInStorage(formValue: any): void {
        let storedFormObj = this.getFormValueInStorage();
        storedFormObj = formValue;
        localStorage.setItem(this.LOCAL_STORAGE_TRY_ALGORITHM + this.contextService.getEnvironment().id, JSON.stringify(storedFormObj));
    }

    getFormValueInStorage(): any {
        let storedFormValues = localStorage.getItem(this.LOCAL_STORAGE_TRY_ALGORITHM + this.contextService.getEnvironment().id);
        return storedFormValues ? JSON.parse(storedFormValues) : {};
    }

    static mapInputToFormField(input: AlgorithmInput, thingDefSelector: boolean): DynamicFormField {
        switch (input.type) {
            case AlgorithmInputType.BOOLEAN:
                return DynamicFormService.newCheckboxField(input.name, input.label || input.name, null, input.mandatory, input.description);
            case AlgorithmInputType.METRIC:
                return DynamicFormService.newMetricField(input.name, input.label || input.name, null, input.mandatory, input.description, thingDefSelector);
            case AlgorithmInputType.LOCATION_METRIC:
                return DynamicFormService.newLocationMetricField(input.name, input.label || input.name, null, input.mandatory, input.description);
            case AlgorithmInputType.FLOAT:
                return DynamicFormService.newNumberField(input.name, input.label || input.name, null, input.mandatory, null, null, null, input.description);
            case AlgorithmInputType.INTEGER:
                return DynamicFormService.newNumberField(input.name, input.label || input.name, null, input.mandatory, null, null, 1, input.description);
            default:
                return DynamicFormService.newStringField(input.name, input.label || input.name, null, input.mandatory, input.description);
        }
    }

    static normalizeName(algorithmName: string): string {
        return algorithmName.replaceAll(' ', '_').replace(/[^a-zA-Z0-9_]/g, '');
    }
}

class AlgorithmTestOutput {
    input: any;
    output: { value: any, log: any };
}