import { Injectable } from "@angular/core";
import { ActionMenuButtons } from "../shared/components/custom-table/custom-table.component";
import { TreeNode } from "../shared/components/tree-list/tree-list.component";

@Injectable({ providedIn: 'root' })
export class TreeService {

    constructor(
    ) { }

    findAllDescendants(id: string, elements: any[], parentIdField: string): string[] {
        let descendantIds: string[] = [];
        const descendantCategories = elements.filter(c => c[parentIdField] == id);
        if (descendantCategories.length) {
            descendantCategories.forEach(dc => {
                descendantIds.push(dc.id);
                descendantIds = descendantIds.concat(this.findAllDescendants(dc.id, elements, parentIdField));
            });
        }
        return descendantIds;
    }

    enrichElementsWithActions(elements: any[], actions: ActionMenuButtons[]): void {
        const actionList = actions.length ? actions : this.getDefaultActions();
        elements.forEach(e => {
            (e as any)['actionMenuButtons'] = actionList;
        });
    }

    private getDefaultActions(): ActionMenuButtons[] {
        let actions = [
            { label: 'Edit', name: 'click', icon: 'edit' },
            { label: 'Delete', name: 'delete', icon: 'delete', buttonClass: 'warn' }
        ];
        return actions;
    }

    fillTreeNodes(elements: any[], parentIdField: string, icon?: string, iconClass?: string): TreeNode[] {
        let tree: TreeNode[] = [];

        // fill root nodes
        elements.forEach(e => {
            if (!e[parentIdField]) {
                let newNode: TreeNode = { id: e.id, name: e.name, description: e.description, children: [], element: e};
                if(icon){
                    newNode.icon = icon;
                    newNode.iconClass = iconClass;
                }
                tree.push(newNode);
            }
        });

        // fill other nodes
        let allIds = elements.map(e => e.id);
        let allChildren = elements.filter(e => e[parentIdField] && allIds.includes(e[parentIdField]));
        while (allChildren.length > 0) {
            let remainingChildren = [];
            for (let child of allChildren) {
                let parent = this.findParentNode(child[parentIdField], tree);
                if (parent) {
                    let newNode: TreeNode = { id: child.id, name: child.name, description: child.description, children: [], element: child };
                    if(icon){
                        newNode.icon = icon;
                        newNode.iconClass = iconClass;
                    }
                    parent.children.push(newNode);
                } else {
                    remainingChildren.push(child);
                }
            }
            if (allChildren.length != remainingChildren.length) {
                allChildren = remainingChildren;
            } else {
                break;
            }
        }
        return tree;
    }

    findParentNode(parentId: string, nodes: TreeNode[]): TreeNode {
        let parent = nodes.find(n => n.id == parentId);
        if (parent) {
            return parent;
        }
        for (let child of nodes) {
            parent = this.findParentNode(parentId, child.children);
            if (parent) {
                return parent;
            }
        }
        return null;
    }

    filterUncoloredNodes(nodes: TreeNode[]): TreeNode[] {
        let filteredNodes: TreeNode[] = [];
        for (let node of nodes) {
            let filteredChildren = this.filterUncoloredNodes(node.children || []);
            if (node.nodeClass || filteredChildren.length) {
                node.children = filteredChildren;
                filteredNodes.push(node);
            }
        }
        return filteredNodes;
    }

}