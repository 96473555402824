import { DynamicFormField } from "../shared/components/dynamic-form/dynamic-form.service";

export interface HomeAssistant {
    id: string;
    thingDefinitionId: string;
    type: HomeAssistantType;
    indexedDeviceCountMetricId: string;
}

export enum HomeAssistantType {
    GOOGLE_HOME = 'GOOGLE_HOME',
    ALEXA = 'ALEXA'
}

export interface HomeAssistantConfig {
    deviceTypes: string[];
    capabilities: HomeAssistantCapabilityConfig[];
}

export interface HomeAssistantCapabilityConfig {
    name: string;
    description: string;
    multiple: boolean;
    properties: DynamicFormField[];
    customForm: string;
}