import { AdminGroup } from "./admin-group";
import { Company } from "./company";
import { ExpertPartner } from "./expert-partner";

export interface User {
    id: string;
    email: string;
    name: string;
    adminGroupId: string;
    adminGroup: AdminGroup;
    expertPartnerId: string;
    expertPartner: ExpertPartner;
    companyId: string;
    company: Company;
    roles: UserRole[];
    creationDate: number;
    state: UserState;
	notifyPlatformUpdatesByEmail: boolean;
	canExportAllUsers: boolean;
    lastNotificationDisplayTimestamp: number;
}

export enum UserRole {
    ADMIN = 'ADMIN',
    CONFIGURATOR = 'CONFIGURATOR',
    BILLING = 'BILLING'
}

export enum UserState {
    PENDING_ACTIVATION = 'PENDING_ACTIVATION',
    ACTIVE = 'ACTIVE'
}
