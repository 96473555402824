export interface UserAgreementDocument {
    id: string;
    name: string;
    title: string;
    text: string;
    language: string;
    lastUpdateTimestamp: number;
    acceptanceRequested: boolean;
    order: number;
}
export const PRIVACY_POLICY_NAME = 'privacy-policy';
export const TERMS_OF_SERVICE_NAME = 'terms-of-service';
export const DEFAULT_LANGUAGE = 'en';
