import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { TextTranslation } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class TextTranslationService extends ResourceService<TextTranslation> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'textTranslations';
    }

    override delete(id: string, extraParams: HttpParams): Promise<void> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    exportFile(language: string, searchText: string): Promise<void> {
        let params = new HttpParams().set('language', language);
        if (searchText) {
            params = params.set('searchText', searchText);
        }
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + this.getEndpoint() + '/export', { observe: "response", responseType: "blob", params: params }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, language + '_translations.xlsx') });
    }

    importFile(file: File, language: string): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);
        let params = new HttpParams().set('language', language);
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.post<void>(AppConstants.API_BASE_URL + this.getEndpoint() + '/import', formData, { params: params }));
    }

}