import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProductModelPart } from "../models";
import { ActionMenuButtons } from "../shared/components/custom-table/custom-table.component";
import { TreeNode } from "../shared/components/tree-list/tree-list.component";
import { ContextService } from "./context.service";
import { FlatTreeNode, FlatTreeService } from "./flat-tree.service";
import { ResourceService } from "./resource.service";
import { TreeService } from "./tree.service";

@Injectable({ providedIn: 'root' })
export class ProductModelPartService extends ResourceService<ProductModelPart> {

    constructor(
        private flatTreeService: FlatTreeService,
        private treeService: TreeService,
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'productModelParts';
    }

    private getActionMenuElements(): ActionMenuButtons[] {
        let actions = [
            { label: 'Edit', name: 'click', icon: 'edit' },
            { label: 'Delete', name: 'delete', icon: 'delete', buttonClass: 'warn' }
        ];
        return actions;
    }

    fillTreeNodes(productModelParts: ProductModelPart[]): TreeNode[] {
        this.treeService.enrichElementsWithActions(productModelParts, this.getActionMenuElements());
        return this.treeService.fillTreeNodes(productModelParts, "parentProductModelPartId");
    }

    getFlatTree(productModelParts: ProductModelPart[]): FlatTreeNode[] {
        const tree = this.fillTreeNodes(productModelParts);
        return this.flatTreeService.getFlatTree(tree);
    }

}