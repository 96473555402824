import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ThingBillingDataSummary } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ThingBillingDataSummaryService extends ResourceService<ThingBillingDataSummary> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'thingBillingDataSummary';
    }

    getByPeriod(year: string, month: string): Promise<ThingBillingDataSummary> {
        let params = new HttpParams().set('year', year).set('month', month);
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.get<ThingBillingDataSummary>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    getPeriods(): Promise<string[]> {
        return firstValueFrom(this.httpClient.get<string[]>(AppConstants.API_BASE_URL + this.getEndpoint() + '/periods', { params: this.getContextParams() }));
    }

}