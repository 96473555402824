import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserCompanyAuthorization } from "../models";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class UserCompanyAuthorizationService extends ResourceService<UserCompanyAuthorization> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'userCompanyAuthorizations';
    }

}