export interface UiTab {
    title: string;
    templateName: string;
    urlPath: string;
    visibilityCondition: string;
    navigation: UiTabNavigationType;
    controlBarTemplateName: string;
}

export enum UiTabNavigationType {
    TAB_MENU = 'TAB_MENU',
    LINK = 'LINK'
}